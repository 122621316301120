export const PLACEHOLDER_SERVICE_REQUEST =
  `{
    "skip": false,
    "path": "f/staging_example/python_script_example",
    "type": "script",
    "async": false,
    "system": "Windmill"
  }`;

export const MUSTACHE_PLACEHOLDER_SERVICE_REQUEST =
  `[
    {
      "request": {
          "connectionType": "CAULDRON",
          "targetConnector": "salesforce",
          "connectionValues": {
              "username": "{{authentication.user_name}}",
              "password": "{{authentication.password}}",
              "securityToken": "{{authentication.security_token}}",
              "isSandbox": "{{is_sandbox}}",
              "isEncrypted": "false"
          }
      },
      "headers": {
          "Content-Type": "application/json",
          "Zuora-User-Id": "{{user.id}}",
          "Zuora-Tenant-Id": "{{tenant.id}}",
          "Host": "{{get_url}}"
      },
      "params": {
          "expected_status_code": 200,
          "method": "POST",
          "url": "{{get_auth_url}}"
      },
      "response": [
          "id",
          "tenantId"
      ],
      "ui_proxy": true
    }
  ]`;
  
