import { Grid, TextField } from '@platform-ui/design-system';
import React, { Dispatch } from "react";
import { Action, useStoreContext } from '../../../../../Store';
import { IntegrationAppFormState } from '../../../state';
import { UPDATE_FIELD_DESCRIPTION_TRANSLATION, UPDATE_FIELD_GROUP_TITLE_TRANSLATION, UPDATE_FIELD_PLACEHOLDER_TRANSLATION, UPDATE_FIELD_TITLE_TRANSLATION } from '../../../actionTypes';
import { Divider } from '@mui/material';

interface LocaleBlockProps {
  fieldGroup: any;
  fieldGroupIndex: number;
  tabName: string;
  tabIndex: number;
  locale: string;
}

function LocaleBlock(props: LocaleBlockProps) {
  const { state, dispatch } = useStoreContext() as { state: IntegrationAppFormState, dispatch: Dispatch<Action> };

  const handleFieldGroupTitleChange = (event) => {
    dispatch({
      type: UPDATE_FIELD_GROUP_TITLE_TRANSLATION,
      payload: {
        tabName: props.tabName,
        tabIndex: props.tabIndex,
        fieldGroupIndex: props.fieldGroupIndex,
        locale: props.locale,
        value: event.target.value
      }
    });
  };

  const handleFieldTitleChange = (fieldIndex) => (event) => {
    dispatch({
      type: UPDATE_FIELD_TITLE_TRANSLATION,
      payload: {
        tabName: props.tabName,
        tabIndex: props.tabIndex,
        fieldGroupIndex: props.fieldGroupIndex,
        locale: props.locale,
        fieldIndex,
        value: event.target.value
      }
    });
  };

  const handleFieldDescriptionChange = (fieldIndex) => (event) => {
    dispatch({
      type: UPDATE_FIELD_DESCRIPTION_TRANSLATION,
      payload: {
        tabName: props.tabName,
        tabIndex: props.tabIndex,
        fieldGroupIndex: props.fieldGroupIndex,
        locale: props.locale,
        fieldIndex,
        value: event.target.value
      }
    });
  };

  const handleFieldPlaceholderChange = (fieldIndex) => (event) => {
    dispatch({
      type: UPDATE_FIELD_PLACEHOLDER_TRANSLATION,
      payload: {
        tabName: props.tabName,
        tabIndex: props.tabIndex,
        fieldGroupIndex: props.fieldGroupIndex,
        locale: props.locale,
        fieldIndex,
        value: event.target.value
      }
    });
  };

  return (
    <>
      <Divider style={{ marginTop: '25px', marginBottom: '20px' }} />
      <Grid container direction='column'>
        <Grid item>
          <TextField
            value={props.fieldGroup?.['titleTranslations']?.[props.locale] || ''}
            label={`Field Group ${props.fieldGroupIndex + 1} Title`}
            dsOnChange={handleFieldGroupTitleChange}
          />
        </Grid>
        <Grid item>
          {
            (props.fieldGroup?.fields || []).map((field, fieldIndex) => (
              <Grid container direction='row'>
                <Grid item xs={3}>
                  <TextField value={field.name} label='Field Name' readonly dsOnChange={() => {}} />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    value={field?.['titleTranslations']?.[props.locale] || ''}
                    label='Field Title'
                    dsOnChange={handleFieldTitleChange(fieldIndex)}
                    required
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    value={field?.['descriptionTranslations']?.[props.locale] || ''}
                    label='Field Description'
                    dsOnChange={handleFieldDescriptionChange(fieldIndex)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    value={field?.['placeholderTranslations']?.[props.locale] || ''}
                    label='Field Placeholder'
                    dsOnChange={handleFieldPlaceholderChange(fieldIndex)}
                  />
                </Grid>
              </Grid>
            ))
          }
        </Grid>
      </Grid>
    </>
  );
}

export default LocaleBlock
